import React, { useEffect, useState, useMemo } from "react";
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import * as actionsNV02 from "../../store/actions/indexNV02";
import { AppState } from "../../store/rootReducer";
import CSharpUtils from "../../felles/CSharpUtils";
import { ProductGroupMetrics } from "../../models/NV02Lava";

const useStyles = makeStyles()(() => ({
  root: {
    margin: 20,
    fontSize: "1em !important",
    color: "#fff",
  },
  container: {
    height: `calc(100vh - 220px)`, // adjust padding/header height here
  overflowY: "auto",
  },
  tableHeader: {
   
    fontWeight: "bold",
  },
  gridItem: {
    backgroundColor: "rgba(29, 78, 137, 0.05)",
    padding: "20px 10px",
    borderRadius: "5px",
    textAlign: "center",
  },
  gridItemTitle: {
    fontWeight: "700",
    color: "#d30535",
    fontSize: "1.2rem",
  },
  gridItemValue: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#333",
  },
}));

const Lave = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const productMetricsData = useSelector((state: AppState) => state.NV02.ProductGroupMetrics);
  const warehouseMetricsData = useSelector((state: AppState) => state.NV02.WarehouseMetrics);

  const ProductGroupsdata = productMetricsData ?? [];
  const data = warehouseMetricsData ?? [];
  console.log("ProductGroupsdata", ProductGroupsdata);

  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [filterColumn, setFilterColumn] = useState<string | null>(null);

  useEffect(() => {
    const companyId = CSharpUtils.T_companyID;
    const warehouse = 10;

    dispatch(actionsNV02.fetchProductMetricsRequest(companyId, warehouse));
    dispatch(actionsNV02.fetchWarehouseMetricsRequest(companyId, warehouse));
  }, [dispatch]);

  const handleFilterChange = (column: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [column]: value.toLowerCase(),
    }));
  };

  const applyFilters = (item: ProductGroupMetrics) => {
    return Object.keys(filters).every((key) => {
      const value = item[key as keyof ProductGroupMetrics]?.toString().toLowerCase();
      return value ? value.includes(filters[key]) : false;
    });
  };

  const filteredData = useMemo(() => {
    return ProductGroupsdata.filter(applyFilters);
  }, [ProductGroupsdata, filters]);

  return (
    <div className={classes.root}>
      <Paper elevation={3} sx={{ padding: 2, maxWidth: "1600px", margin: "20px auto" }}>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={4} className={classes.gridItem}>
            <Typography className={classes.gridItemTitle}>Total LAGERVERDI</Typography>
            <Typography className={classes.gridItemValue}>
              {data.length > 0
                ? data[0].totalWarehouseValue.toLocaleString("no-NO", {
                    style: "currency",
                    currency: "NOK",
                    minimumFractionDigits: 2,
                  })
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <Typography className={classes.gridItemTitle}>ANT. ARTIKLER</Typography>
            <Typography className={classes.gridItemValue}>
              {data.length > 0 ? data[0].totalLines.toLocaleString("no-NO") : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <Typography className={classes.gridItemTitle}>ANT. MED BEH.</Typography>
            <Typography className={classes.gridItemValue}>
              {data.length > 0 ? data[0].totalLinesWStock.toLocaleString("no-NO") : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {["Varegruppe", "Beskrivelse", "Antall", "Antall med beholdning", "Lagerverdi"].map((col) => (
                <TableCell key={col} className={classes.tableHeader}>
                  <Box display="flex" alignItems="center">
                    {col.toUpperCase()}
                    <IconButton size="small" onClick={() => setFilterColumn(col)}>
                      <FilterAlt />
                    </IconButton>
                  </Box>
                  {filterColumn === col && (
                    <TextField
                      size="small"
                      variant="outlined"
                      value={filters[col] || ""}
                      onChange={(e) => handleFilterChange(col, e.target.value)}
                      sx={{ marginTop: 1 }}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Ingen resultater funnet
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.group}</TableCell>
                  <TableCell>{row.description || "N/A"}</TableCell>
                  <TableCell>{row.lines}</TableCell>
                  <TableCell>{row.linesWStock}</TableCell>
                  <TableCell>
                    {typeof row.totalValue === "number"
                      ? row.totalValue.toLocaleString("no-NO", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        })
                      : "-"}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Lave;
