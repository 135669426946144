import { ProductGroupMetrics,WarehouseMetrics } from '../../models/NV02Lava';
import * as actionTypesNV02 from '../actions/actionTypesNV02';
import * as fromApp from "../rootReducer";

export interface NV02 {
  ProductGroupMetrics: ProductGroupMetrics[];
  WarehouseMetrics: WarehouseMetrics[];
  error: any;
  isFetching: boolean;
}
const initialState: NV02 = {
  ProductGroupMetrics: [],
  WarehouseMetrics: [],
  error: undefined,
  isFetching: false,
};

const NV02Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypesNV02.WAREHOUSE_METRICS_GET:
    return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNV02.WAREHOUSE_METRICS_GET_SUCCESS:
      return {
        ...state,
        WarehouseMetrics: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actionTypesNV02.WAREHOUSE_METRICS_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
      case actionTypesNV02.WAREHOUSE_METRICS_RESET:
      
        return{
          ...initialState
        };  
        case actionTypesNV02.PRODUCT_METRICS_GET:
          return {
            ...state,
            isFetching: true,
            error: undefined,
          };
    
        case actionTypesNV02.PRODUCT_METRICS_GET_SUCCESS:
          return {
            ...state,
            ProductGroupMetrics: action.payload,
            isFetching: false,
            error: undefined,
          };
    
        case actionTypesNV02.PRODUCT_METRICS_GET_FAILURE:
          return {
            ...state,
            isFetching: false,
            error: action.payload,
          };
    
        case actionTypesNV02.PRODUCT_METRICS_RESET:
          return {
            ...initialState,
          };     
      
    default:
      return state;
  }
};

export default NV02Reducer;