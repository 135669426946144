import createSagaMiddleware from 'redux-saga';
import { history } from './history';
import rootReducer from './rootReducer';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { configureStore } from '@reduxjs/toolkit';
import {
    WatchCriterialListToExcelSaga,
    watchACC1CSVListFetch,
    watchACC1ListFetch,
    watchAccoutingJobListDeleteSelected,
    watchAccoutingJobListExportSelected,
    watchAuthAutoLoginSubmit,
    watchAuthLoginFormSubmit,
    watchAuthLogoutFormSubmit,
    watchAuthPassRecoveryFormSubmit,
    watchAuthPassResetFormSubmit,
    watchBugReportPostSaga,
    watchCriteriaItemDeletionSaga,
    watchCriteriaItemRegistrationSaga,
    watchCriteriaItemUpdateSaga,
    watchPostUploadExcelDocumentCriteria,
    watchFavorittListeDeletionSaga,
    watchFavorittListeGetReportSaga,
    watchFavorittListeListGetSaga,
    watchFavorittListeUpdateSaga,
    watchFetchMenuItems,
    watchGetAdminFirmListSaga,
    watchGetDriftPost,
    watchGetGroupListSaga,
    watchGetNyheterPost,
    watchGetPDFdata,
    watchGetTransById,
    watchGetUserList,
    watchGetUserListExportToData,
    watchHelpGet,
    watchLogToApiSaga,
    watchPostFeedbackdata,
    watchPostFormData,
    watchPostToWebSocket,
    watchUserDataFetchSaga,
    watchUserDataUpdateSaga,
    watchUserListDeletionSaga,
    watchUserLogouFromTerminal,
    watchUserProfileDataGet,
    watchUserProfileUpdateData,
    watchUserRegistrationSaga,
    watchcriteriaItemListGetSaga,
    watchResetPredefinedReduxTransactionStoreSaga
} from './________sagas________';
import { watchGetNV03ItemListSaga } from './________sagas________/indexNV03';
import { watchfetchTradingPartnersSaga } from './________sagas________/indexNO01';
import { watchfetchSALGSaga } from './________sagas________/indexNV01';
import { watchFetchProductMetricsSaga } from './________sagas________/indexNV02';


export default function myStore() {
    const routerMiddleware = createRouterMiddleware(history);
    const sagaMiddleware = createSagaMiddleware();

    //const customizedMiddleware = ...getDefaultMiddleware()({
    //  serializableCheck: false
    //})

    const middlewares = [/*...getDefaultMiddleware(),*/ routerMiddleware, sagaMiddleware];
    //const middlewareEnhancer = applyMiddleware(...middlewares);
    //const enhancers = [middlewareEnhancer/*, monitorReducersEnhancer*/]
    //const composedEnhancers = composeWithDevTools(...enhancers)

    // ENABLE REDUX DEV TOOLS
    //const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
    //const composeEnhancers = compose; 

    if (process.env.NODE_ENV === 'development') {
        //middlewares.push(logger);
    }

    const store = configureStore({
        reducer: rootReducer(history),
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                thunk: false,
                immutableCheck: false,
                serializableCheck: false
            }).concat(...middlewares), //.concat(sagaMiddleware),
        devTools: process.env.NODE_ENV !== 'production'
        //enhancers:middlewareEnhancer,
        //composedEnhancers
        // other store enhancers if any
        // ,/* devTools:[ devToolsEnhancer({ realtime: true }) ],*/


    })

    // SAGAS Should be defined into dedicated files
    //AUTH USER
    sagaMiddleware.run(watchAuthLoginFormSubmit);
    sagaMiddleware.run(watchAuthAutoLoginSubmit);
    sagaMiddleware.run(watchAuthLogoutFormSubmit);
    sagaMiddleware.run(watchAuthPassRecoveryFormSubmit);
    sagaMiddleware.run(watchAuthPassResetFormSubmit);

    //ACCOUNTING
    sagaMiddleware.run(watchACC1ListFetch);
    sagaMiddleware.run(watchAccoutingJobListDeleteSelected);
    sagaMiddleware.run(watchAccoutingJobListExportSelected);
    sagaMiddleware.run(watchACC1CSVListFetch);

    //Menu
    sagaMiddleware.run(watchFetchMenuItems);

    //Heirloom
    sagaMiddleware.run(watchGetTransById);
    sagaMiddleware.run(watchHelpGet);
    sagaMiddleware.run(watchPostFormData);

    //PDF
    sagaMiddleware.run(watchGetPDFdata);

    //Profile
    sagaMiddleware.run(watchUserProfileDataGet);
    sagaMiddleware.run(watchUserProfileUpdateData);

    // Support
    sagaMiddleware.run(watchPostFeedbackdata);

    // Utils
    sagaMiddleware.run(watchGetNyheterPost);
    sagaMiddleware.run(watchGetDriftPost);
    sagaMiddleware.run(watchLogToApiSaga);

    // User Admin 
    sagaMiddleware.run(watchGetUserList);
    sagaMiddleware.run(watchGetUserListExportToData);
    sagaMiddleware.run(watchUserDataFetchSaga);
    sagaMiddleware.run(watchUserDataUpdateSaga);
    sagaMiddleware.run(watchUserRegistrationSaga);
    sagaMiddleware.run(watchUserListDeletionSaga);
    sagaMiddleware.run(watchGetGroupListSaga);
    sagaMiddleware.run(watchPostToWebSocket);
    sagaMiddleware.run(watchGetAdminFirmListSaga);
    sagaMiddleware.run(watchUserLogouFromTerminal);

    // Tallenes Tale
    sagaMiddleware.run(watchcriteriaItemListGetSaga);
    sagaMiddleware.run(watchCriteriaItemUpdateSaga);
    sagaMiddleware.run(watchCriteriaItemRegistrationSaga);
    sagaMiddleware.run(watchCriteriaItemDeletionSaga);
    sagaMiddleware.run(WatchCriterialListToExcelSaga);
    sagaMiddleware.run(watchFavorittListeListGetSaga);
    sagaMiddleware.run(watchFavorittListeUpdateSaga);
    sagaMiddleware.run(watchFavorittListeDeletionSaga);
    sagaMiddleware.run(watchFavorittListeGetReportSaga);
    sagaMiddleware.run(watchPostUploadExcelDocumentCriteria);
    sagaMiddleware.run(watchBugReportPostSaga);

    //NV03
    sagaMiddleware.run(watchGetNV03ItemListSaga);

    //NV01
    sagaMiddleware.run(watchfetchTradingPartnersSaga);
    sagaMiddleware.run(watchFetchProductMetricsSaga);


    
    //NV01SALG
    sagaMiddleware.run(watchfetchSALGSaga);

    
    //reset selected store branch
    sagaMiddleware.run(watchResetPredefinedReduxTransactionStoreSaga);

    return store;
}
