// import ReactNumeric from 'react-numeric';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import React, { Component, MouseEvent, createRef } from 'react';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import OpenMWVitec from '../../components/OpenMWVitec';
import E from '../../felles/Enums';
import U from '../../felles/Utils';
import C from '../../felles/UtilsCSS';
import HjelpFormData from '../../models/HjelpFormData';
import MenuEl from '../../models/MenuEl';
import MenuItemTarget from '../../models/MenuItemTarget';
import PageImpl from '../../models/PageImpl';
import HeirloomFormData from '../../models/payload/HeirloomFormData';
import * as actions from '../../store/actions/index';
import * as actionsNV03 from '../../store/actions/indexNV03';
import { history } from '../../store/history';
import { AppState } from '../../store/rootReducer';

/*
 *                                                    VSEContent
 */
/* **********************************************************************************************************/
class VSEContent extends Component<Props> {
  zForm: JSX.Element | never[] = [];
  parsedContent: string | JSX.Element[] | JSX.Element = [];
  zFormChildren: JSX.Element[] = [];
  formRef: any = createRef();
  trans = '';
  runComponentUpdate = false;
  IsF3MenuKeyEnabled = false;
  updateBugReport: any = false;
  htmlContentAsString: any = undefined;

  // Store the function reference
  private handleKeyDown = (e: KeyboardEvent) => this.handlePressedKey(e);

  // Store the function reference
  private handleKeyUp = (e: KeyboardEvent) => this.handlePressedKeyUp(e);

  //
  handleResize() {
    const _target: HTMLFormElement = this.formRef.current;
    if (_target?.length !== -1 && this.props && this.props?.currentFieldwithFocus && this.props?.currentFieldwithFocus?.getAttribute('name') !== null)
      this.handleFocusAssingment(this.props?.currentFieldwithFocus?.getAttribute('name'));
  }

  //Init
  componentDidMount() {
    this.updateBugReport = true;
    this.handleHTMLResponseUpdate();
    this.runComponentUpdate = true;
    window.addEventListener('blur', U.debounce(() => { this.handleResize() }));
    window.addEventListener("keydown", this.handleKeyDown, false);
    window.addEventListener("keyup", this.handleKeyUp, false);
  }

  //Update
  componentDidUpdate() {
    if (this.props.htmlContent) {
      if (this.props.keyValuePairs4VSE.length > 2 && !this.props.isTransPostInProgress) {
        this.submitVSEForm('enter');
        return;
      }
      this.handleHTMLResponseUpdate();
    }
    //
    if (this.runComponentUpdate /*!this.props.isTransGetInProgress && this.props.fieldToAttachFocus*/) {
      this.handleFocusAssingment(this.props.fieldToAttachFocus);
    }
    this.runComponentUpdate = true;
  }

  componentWillUnmount() {
    this.props.userLogoutFromTerminal();
    window.removeEventListener(
      'blur',
      U.debounce(() => {
        this.handleResize();
      })
    );
    window.removeEventListener("keydown", this.handleKeyDown, false);
    window.removeEventListener("keyup", this.handleKeyUp, false);
    this.props.transGetSuccess(null, '');
  }

  //
  handlerInputClicked(e: any /*, _this: VSEContent*/) {
    const _target: HTMLFormElement = this.formRef.current;
    const _input = e.target?.id || e.id;
    this.runComponentUpdate = false;
    if (_target && _input) {
      for (let i = 0; i < _target.length; i++) {
        if (_target.elements[i].getAttribute('name') === 'DFH_CURSOR') {
          _target.elements[i].setAttribute('value', _input);
        }
      }
    }
    //
    this.props.setCurrentFieldwithFocus(e.target || e);
  }

  // U to hangle change focus for the 1 char sized input field
  handlerKeyUpInputChanged(e: React.FormEvent<HTMLInputElement>) {
    // list of key to not to reponsd
    const excludedKey: number[] = [37, 38, 39, 40, 9, 16];
    //
    if (
      !excludedKey.includes((e as any).keyCode) &&
      e.currentTarget.size === 1 &&
      (e.target as any).value.length + '' === (e.target as any).getAttribute('size')
    ) {
      this.handleFocusAssingment((e.target as any).name);
    }
  }

  //
  handlerInputChanged(e: React.FormEvent<HTMLInputElement>) {
    (e.target as any).setAttribute('data-state', 1);
    switch (true) {
      case (e.target as any).value.length === 0:
        (e.target as any).setAttribute('data-state', 2);
        break;
      default:
        (e.target as any).setAttribute('data-state', 1);
        break;
    }
    //
    this.props.setCurrentField((e.target as any).name);
    this.props.updateInputState((e.target as any).name, (e.target as any).value);
    this.props.setSelectionEnabled(false);

    // TODO: IMPORTANT TO CHECK WHAT runComponentUpdate MEANS
    this.runComponentUpdate = false;
    if ((e.target as any).value.length + '' === (e.target as any).getAttribute('size')) {
      this.handleFocusAssingment((e.target as any).name);
    }
  }

  //
  handlerOnBlurChanged = (e: React.FormEvent<HTMLInputElement>) => {
    //remove focus from the selected field
    if (this.props.recordingState > 0 && (e.target as HTMLFormElement)?.name != undefined) {
      this.props.updateRecordingLog(<>{'HTMLInputElement --> ' + (e.target as HTMLFormElement)?.name}  {' Value: ' + (e.target as HTMLFormElement)?.value}<br /> </>);
    }
    e.currentTarget.blur();
  };

  //
  handleSubmit(event) {
    event.preventDefault();
  }

  //
  handlePressedEndKey(event: any = null) {
    event?.preventDefault();
    const n = this.props.menuItemTarget;
    if (n !== undefined) {
      this.props.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.tilpassetBeta
        , n.kodeSynlig, n.labelSynlig, n.hasChildren, n.title));
      this.props.menuItemSetSelected([n.rootItem, n.item + n.rootItem]);
      //
      if (this.props.urlCallBack !== '') {
        history.push(U.HOMEPAGE_CONTEXT + '/' + this.props.urlCallBack);
        return;
      }
      //      
      history.push(U.HOMEPAGE_CONTEXT + '/menu');
    }
  }
  //
  handlePressedHomeKey(/*key, event: any = null*/) {
    if (this.props.defaultFieldToAttachFocus === (document.activeElement as any).getAttribute('name')) {
      this.props.setSearchInputFocused(true);
      return;
    }
    this.handleFocusAssingment(this.props.defaultFieldToAttachFocus, true);
  }
  cLi = -1;
  cO = -1;
  //
  handlePressedArrowsKey(key) {
    let cF: any = undefined;
    const _target: any = this.formRef.current;
    this.cLi = -1;
    this.cO = -1;
    const irl: any = this.props.inputArrayLineState || {};
    let isTab = false;
    let isDown = false;
    let rangeSel: any = undefined;
    for (let i = 0; i < _target.length; i++) {
      if (_target.elements[i] === document.activeElement && _target.elements[i].getAttribute('class').includes('line')) {
        cF = _target.elements[i];
        rangeSel = U.getTextSelection(cF);
        if (irl) {
          irl.forEach((elA, m) => {
            elA.forEach((elB, n) => {
              if (elB === _target.elements[i].getAttribute('name')) {
                this.cLi = m;
                this.cO = n;
              }
            });
          });
        } else console.log('irl is undefined');
      }
    }
    //
    let inputTarget = undefined;
    switch (true) {
      case key === 'arrowleft':
        switch (true) {
          case rangeSel.start === 0 && rangeSel.end === 0:
            if (irl) {
              inputTarget =
                irl[this.cLi] && irl[this.cLi][this.cO - 1]
                  ? irl[this.cLi][this.cO - 1]
                  : irl[this.cLi - 1]
                    ? irl[this.cLi - 1][irl[this.cLi - 1].length - 1]
                    : undefined;
            }
            break;
          case rangeSel.start === 0 && rangeSel.end === cF?.value.length:
            cF.setSelectionRange(0, 0);
            break;
          case cF?.selectionStart !== 0:
            cF.setSelectionRange(cF?.selectionStart - 1, cF?.selectionStart - 1);
            break;
          default:
            break;
        }
        break;
      case key === 'tab':
        isTab = true;
        inputTarget = irl[this.cLi] && irl[this.cLi][this.cO + 1] ? irl[this.cLi][this.cO + 1] : irl[this.cLi + 1] ? irl[this.cLi + 1][0] : undefined;
        break;
      case key === 'arrowright':
        switch (true) {
          case rangeSel.end === cF?.value.length && rangeSel.start === 0:
            cF.setSelectionRange(rangeSel.end, rangeSel.end);
            break;
          case cF?.value.length === cF?.selectionStart || U.getTextSelection(cF) === cF?.selectionStart:
            inputTarget = irl[this.cLi] && irl[this.cLi][this.cO + 1] ? irl[this.cLi][this.cO + 1] : irl[this.cLi + 1] ? irl[this.cLi + 1][0] : undefined;
            break;
          case cF?.selectionStart < cF?.value.length:
            cF.setSelectionRange(cF?.selectionStart + 1, cF?.selectionStart + 1);
            break;
          default:
            break;
        }
        break;
      case key === 'arrowdown':
        // inputTarget = irl[this.cLi + 1] && irl[this.cLi + 1][0] != undefined ? irl[this.cLi + 1][0] : undefined;
        isDown = true;
        inputTarget =
        irl[this.cLi + 1] && irl[this.cLi + 1][this.cO]
          ? irl[this.cLi + 1][this.cO]
          : irl[this.cLi + 1]
            ? irl[this.cLi + 1][irl[this.cLi + 1].length - 1]
            : undefined;
        break;
      case key === 'arrowup':
        inputTarget =
          irl[this.cLi - 1] && irl[this.cLi - 1][this.cO]
            ? irl[this.cLi - 1][this.cO]
            : irl[this.cLi - 1]
              ? irl[this.cLi - 1][irl[this.cLi - 1].length - 1]
              : undefined;
        break;
      default:
        break;
    }

      /**/
      if (inputTarget) {
        this.props.setSelectionEnabled(true);
        if (isTab || isDown) {
          this.handleFocusAssingmentNext(inputTarget);
        } else {
          this.handleFocusAssingment(inputTarget);
        }
        this.handlerInputClicked(document.activeElement);
      } /**/
  
  }

  // Handle the key up event - occurs when a key is released
  handlePressedKeyUp(event) {
    // Check if the key is valid
    const { key } = event;
    //  Check if the key is valid
    if ((key === 'F7' || key === 'F8') && this.props.currentRowFocused !== -1 && this.props.page.content?.length > 0) {
      //  Get the index of the current row focused
      const index = this.props.page.content.findIndex(item => item.id === this.props.currentRowFocused);
      //  Check if the key is F7 and the previous row is not null
      if (key === 'F7' && this.props.page.content[index - 1]) {
        const n = this.props.page.content[index - 1];
        const fData = 'transid=VAVE' +
          '&SKODE=X' +
          '&SALFA=' + n.alfa.trim() +
          '&SARTNR=' + n.artikkelNummer.trim() +
          '&SEDBNR=' + n.edbNr.trim() +
          '&VGR=' + n.vareGruppe.trim() +
          'DFH_PF7: PF7'
        this.props.setCurrentRowFocused(this.props.page.content[index - 1].id);
        this.props.postFormData(new HeirloomFormData(this.props.menuItemTarget?.code, '', fData + E.getInputParamfromKeyPressed(key), this.props.termId));
      }
      //  Check if the key is F() and the next row is not null
      if (key === 'F8' && this.props.page.content[index + 1]) {
        const n = this.props.page.content[index + 1];
        const fData = 'transid=VAVE' +
          '&SKODE=X' +
          '&SALFA=' + n.alfa.trim() +
          '&SARTNR=' + n.artikkelNummer.trim() +
          '&SEDBNR=' + n.edbNr.trim() +
          '&VGR=' + n.vareGruppe.trim() +
          'DFH_PF8: PF8'
        this.props.setCurrentRowFocused(this.props.page.content[index + 1].id);
        this.props.postFormData(new HeirloomFormData(this.props.menuItemTarget?.code, '', fData + E.getInputParamfromKeyPressed(key), this.props.termId));
      }
      return;
    }
  }

  //
  handlePressedKey(event) {
    //
    const { key } = event;
    // Check the transaction code
    const code = this.props.menuItemTarget?.code || 'XZX';

    // Debug Til7
    // console.log('(e.target as any).value' + (event.target as any).value);
    
    // NumpadEnter is triggered automatically when the user use the article scanner
    if (!this.props.isTransPostInProgress && (event as any)?.code?.toString() === 'NumpadEnter'
      && (code === 'TIL7'
        /* || code === 'TIL6'*/
        //|| this.trans === 'OP12'
      )) {
      // we don't want to submit the form when the user is scanning the article
      return;
    }
    // Check if the key is valid
    let __KEY: string;
    switch (true) {
      case key === undefined && event.currentTarget.value !== undefined:
        __KEY = event.currentTarget.value.toLowerCase();
        break;
      case event.ctrlKey && event.shiftKey:
        __KEY = 'ctrl + shift + ' + key?.toLowerCase();
        break;
      case event.ctrlKey && !event.shiftKey:
        __KEY = 'ctrl + ' + key?.toLowerCase();
        break;
      case !event.ctrlKey && event.shiftKey:
        __KEY = 'shift + ' + key?.toLowerCase();
        break;
      default:
        __KEY = key?.toLowerCase();
        break;
    }
    if (!E.validKey.includes(__KEY)) {
      return;
    }
    //
    event.preventDefault();
    //
    if (this.props.showOpenModalWindow) return;
    //
    if (__KEY === 'enter' && this.props.showHelpContent) {
      this.updateBugReport = true;
      this.props.setShowHelpContent(false);
      return;
    }
    // Do nothing - like not submitting the form
    if ((__KEY === 'f7' || __KEY === 'f8') && this.props.currentRowFocused !== -1 && this.props.page.content?.length > 0) {
      return;
    }
    //
    if (__KEY === 'ctrl + s' && !this.props.showHelpContent /**/) {
      U.giveFocusToSearchField();
      return;
    }

    // Block enter / form submit flodding.
    if (this.props.isTransPostInProgress) {
      return;
    }
    //
    if (__KEY === 'arrowleft' || __KEY === 'arrowright' || __KEY === 'arrowup' || __KEY === 'arrowdown' || __KEY === 'tab') {
      this.handlePressedArrowsKey(__KEY);
      return;
    } else if (__KEY === 'shift + home') {
      this.handlePressedHomeKey(/*__KEY, event*/);
      return;
    } else if (__KEY === 'shift + end') {
      this.handlePressedEndKey(event);
      return;
    } else if ((__KEY === 'shift + f1' || __KEY === 'f1') && !this.props.istransVSEHelpFetchInProgress) {
      //key === 'ctrl + shift + f1' || */
      // Default
      let fieldId = '001000';
      let fieldValue = '';
      let elementId = '';
      // Cycles through all field to detec if one has the focus.
      //let isAFieldWithFocus: boolean = false;
      this.runComponentUpdate = false;
      this.props.inputArrayState?.forEach((element) => {
        if (!element.name.includes('_')) {
          const el = document.getElementById(element.id);
          if (el === document.activeElement) {
            //  isAFieldWithFocus = true;
            elementId = element.id;
          }
        }
      });
      //
      if (elementId !== '') {
        const elB = document.getElementById(elementId);
        fieldId = (elB as HTMLElement).getAttribute('id')?.toString() || '001000';
        fieldValue = (elB as HTMLElement).getAttribute('value')?.toUpperCase() || '';
      }
      this.props.setCurrentField(fieldId);
      const hD = !this.props.showHelpContent
        ? new HjelpFormData(this.trans, fieldId, fieldValue, this.props.termId?.substring(0, 4), this.props.termId?.substring(4, this.props.termId.length))
        : new HjelpFormData(this.trans, '001000', '', this.props.termId?.substring(0, 4), this.props.termId?.substring(4, this.props.termId.length));
      //
      this.props.transHelpGetSuccess('loading');
      this.props.setHelpParams(hD);
      this.props.transHelpGet(hD);
      this.props.setShowHelpContent(true);
      return;
    } else if (__KEY === 'f3' && this.props.showHelpContent) {
      this.props.setShowHelpContent(false);
      return;
    } else if (__KEY === 'f3' && !this.props.showHelpContent && this.IsF3MenuKeyEnabled) {
      this.handlePressedEndKey(event);
      return;
    } else if (__KEY === 'ctrl + shift + f1') {
      //
      this.props.setShowOpenModalWindow(!this.props.showOpenModalWindow);
      return;
    }
    /*************** POST THE FORM ***********/
    this.submitVSEForm(__KEY);
  }

  /*
   *
   */
  submitVSEForm(key = '') {
    //
    const target: any = this.formRef.current;
    let formData = '';
    // fix for ipad or Ipad user must use Options key + '-', ... '————————-'
    for (let i = 0; i < target.length; i++) {
      const fName = target.elements[i].getAttribute('name');
      let fValue = target.elements[i].getAttribute('data-state') === '2' ? ' ' : encodeURIComponent(target.elements[i].value.toUpperCase());
      // fix for ipad or Ipad user must use Options key + '-', ... '————————-'
      fValue = fValue.replaceAll('%E2%80%94', '--');
      //
      if (fName === 'DFH_CURSOR' && this.props.currentFieldwithFocus) fValue = this.props.currentFieldwithFocus?.getAttribute('name');
      formData += (i > 0 ? '&' : '') + fName + '=' + fValue;
    }
    //
    const reg = this.props.updateKey;
    reg(key.toString().toUpperCase());
    // Avoid repeats
    this.props.postFormData(new HeirloomFormData(
      this.props.menuItemTarget?.code,
      '',
      formData + E.getInputParamfromKeyPressed(key),
      this.props.termId,
      '',
      false,
      false,
      -1
    ));
    setTimeout(function () {
      reg('');
    }, 600);

    if (this.props.recordingState > 0) {
      this.updateBugReport = true;
    }
    this.props.resetKeyValuePairs4VSE();// = [];
  }

  submitWebSocketForm(wesocketMessageFormData: string) {
    this.props.transFormDataToWS(wesocketMessageFormData);
  }

  /****************************************************************************************************/
  /****************************************************************************************************/
  /****************************************************************************************************/
  /****************************************************************************************************/
  /************************************** PARSE AND CONVERT VSE ***************************************/
  /****************************************************************************************************/
  /****************************************************************************************************/
  /****************************************************************************************************/
  /****************************************************************************************************/
  //
  handleHTMLResponseUpdate() {
    const { htmlContent/*, classes*/ } = this.props;
    this.IsF3MenuKeyEnabled = false;

    //Fetching the body el as string
    if (htmlContent) {
      // A check to determine if we update the BugLogReport
      if (this.htmlContentAsString === undefined) {
        this.updateBugReport = true;
      } else if (this.htmlContentAsString !== undefined && this.htmlContentAsString !== htmlContent) {
        this.updateBugReport = true;
      } else this.updateBugReport = false;

      //
      if (htmlContent.body.textContent?.includes('PROG.ORD1')) {
        this.handlePressedEndKey();
        return;
      }

      //
      if (htmlContent.getElementById('buttonpanel')) {
        htmlContent.getElementById('buttonpanel')?.parentElement?.remove();
      }

      //
      const innerHTML: string = htmlContent.body.innerHTML;

      // Options for the HTML parser
      const secondPass = {
        replace: (domNode) => {
          if (!domNode.attribs) {
            return;
          }
          if (
            !U.isEmpty(this.props.keyBindingFieldInputStateObj) &&
            domNode.name.toString() === 'input' &&
            domNode.attribs.onfocus &&
            domNode.attribs.onfocus === 'setCurrentField(this)'
          ) {
            // Creating new Obj Attributes without onfocus and onfocusout
            const { onfocus, onfocusout, oninput, /* value,*/ ...newAttribs } = domNode.attribs;

            // Happy Eslint!
            onfocus;
            // Happy Eslint!
            onfocusout;
            // assigning the new attribute obj to the node
            domNode.attribs = { ...newAttribs };
            // adding SyntheticEvent
            domNode.attribs.onClick = (e) => this.handlerInputClicked(e);
            if (this.props.keyBindingFieldInputStateObj)
              domNode.attribs.value = this.props.keyBindingFieldInputStateObj['' + domNode.attribs.name] ? this.props.keyBindingFieldInputStateObj['' + domNode.attribs.name] : '';
            //
            if (this.props.keyValuePairs4VSE.length > 1) {
              const pair = this.props.keyValuePairs4VSE.find(pair => pair.key === domNode.attribs.name);
              //
              if (domNode.attribs.value === '') {
                domNode.attribs.value = pair?.value || ''
              }
            }
            //if (keyBindingFieldInputStateObj) 
            //domNode.attribs.value = keyBindingFieldInputStateObj['' + domNode.attribs.name] ? keyBindingFieldInputStateObj['' + domNode.attribs.name] : '';
            //domNode.attribs.ref = 'searchString';
            domNode.attribs.onChange = (e) => this.handlerInputChanged(e);
            domNode.attribs.onKeyUp = (e) => this.handlerKeyUpInputChanged(e);
            // domNode.attribs.onFocus = this.handlerOnFocusChanged;
            // domNode.attribs.onFocusOut = this.handlerOnFocusChanged;
            domNode.attribs.onBlur = (e) => this.handlerOnBlurChanged(e);
            // domNode.attribs.onInput;
            // New attriv - Hack to force data deletion
            // 0 unchanged
            // 1
            // 2 empty, and set a space char for the server to delete the record.
            domNode.attribs['data-state'] = '0';
            domNode.attribs['enterkeyhint'] = 'enter';
            // Example with Numerics
            // domNode.attribs.currencySymbol="€"
            // return <ReactNumeric {...props} />;
            // assembling props
            const props = attributesToProps(domNode.attribs);
            // Return the new input node
            return <input title={domNode.attribs.name} autoComplete="off" {...props} />;
          }
          //
          else if (
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('f3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('f3 = meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('pf3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('p3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('f3 meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('3=meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('meny=f3') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('meny=3') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('meny=pf3') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('pf3=til meny') ||
            ((domNode as any).firstChild?.data as string)?.toLowerCase().includes('til meny=pf3')
          ) {
            this.IsF3MenuKeyEnabled = true;
          }
          // Check if the current programme is include to the custom list enabling redirection to the previous menu.
          else if (((domNode as any).firstChild?.data as string)?.toLowerCase().includes('prog:')) {
            this.trans = (domNode as any).firstChild?.data as string;
            this.trans = this.trans?.split(':')[1];
            this.trans = this.trans?.replaceAll(' ', '');
            this.trans = this.trans?.substring(0, 4)
            if (this.trans.length > 3) {
              this.trans = this.trans.substring(0, 4);
              if (E.programIncludeListWithF3MenyKeyEnabled.includes(this.trans)
                || E.programIncludeListWithF3MenyKeyEnabled.includes(this.props.menuItemTarget?.code as string)) {
                this.IsF3MenuKeyEnabled = true;

              }
              if (E.programExcludeListWithF3MenyKeyDisabled.includes(this.trans)) {
                this.IsF3MenuKeyEnabled = false;
              }
            }
            this.props.transSetProgrameId(this.trans);

          }
        },
      };
      // Options for the HTML parser
      const firstPass = {
        replace: (domNode) => {
          if (!domNode.attribs) {
            return;
          }
          if (domNode.name.toString() === 'div' && domNode.attribs.id === 'screenimage') {
            //domNode.attribs.class = classes.bob;
            const props = attributesToProps(domNode.attribs);
            return <div {...props}>{domToReact(domNode.children, secondPass)}
              {/* This field helps the transcation to get the focus when no input field are provided */}
              {/*this.props.inputArrayLineState?.length === 0
                ? <input id={'dcField'} name={'dcField'} autoComplete="off" style={{ opacity: 0, border: 'none', width: 1, height: 1 }} />
                : null
          */ }
            </div>;
          }
        },
      };
      // Converts string to ReadtDomEl
      this.parsedContent = parse(innerHTML, firstPass);

      // isolating the form HTML element
      this.zForm = (this.parsedContent as JSX.Element[])[1];
      //
      if (this.props.recordingState > 0 && this.updateBugReport) {
        this.htmlContentAsString = htmlContent;
        this.props.updateRecordingLog(this.zForm);
        this.updateBugReport = false;
      }


      // isolating the children of form HTML element
      // More debug logic required
      return (this.zFormChildren = this.zForm ? this.zForm?.props?.children : [<span>Loading...</span>]);
    }
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  index = -1;
  //
  handleFocusAssingment($id = '', isLengthSelectedOnFocus = false) {
    //
    this.index = -1;
    const target: any = this.formRef.current;

    //
    if (target) {
      for (let i = 0; i < target.length; i++) {
        const objtoFocus: HTMLFormElement = target.elements[i];
        if (objtoFocus.nodeName.toString() === 'INPUT' && objtoFocus.getAttribute('id') && $id !== '') {
          if (objtoFocus.getAttribute('id') === $id) {
            objtoFocus.focus();
            // onLoad register de default field with focus
            this.props.setCurrentFieldwithFocus(objtoFocus);
            objtoFocus.select();
            if (objtoFocus.getAttribute('size') === '1') {
              objtoFocus.nodeValue = '';
            }
            //
            if (this.props.isFieldSelectEnabled === true || isLengthSelectedOnFocus === true) {
              objtoFocus.select();
              //
              if (objtoFocus.getAttribute('size') === '1') {
                this.props.inputArrayState?.forEach((element, i) => {
                  if (element.name === objtoFocus.name) {
                    this.index = i;
                  }
                });
                return;
              }
            } else {
              objtoFocus.selectionEnd = target.elements[i].value.length;
            }
            if (objtoFocus.getAttribute('size')) {
              const num = objtoFocus.getAttribute('size') || '-1';
              if (objtoFocus.value.length === parseInt(num)) {
                this.props.inputArrayState?.forEach((element, i) => {
                  if (!element.name.includes('_') && element.name === objtoFocus.name) {
                    this.index = i;
                  }
                });
                //
                if (this.props.inputArrayState !== undefined) {
                  const pInp =
                    this.props.inputArrayState[this.index + 1]?.type === 'hidden' ? this.props.inputArrayState[this.index + 2]
                      : this.props.inputArrayState[this.index + 1];
                  if (pInp && !this.props.isFieldSelectEnabled) {
                    if (!pInp.name.includes('_')) {
                      this.handleFocusAssingmentNext(pInp.name);
                    }
                  }
                }
              }
            }
          }
        }
        // Gives focus to the first visible input if value is not provided by the API
        else if ($id === '' && objtoFocus.nodeName.toString() === 'INPUT' && objtoFocus.getAttribute('id')) {
          // Gives focus
          objtoFocus.focus();
          objtoFocus.select();
          break;
        }
      }
    }
    //Gives focus to the form
    if (this.props.fieldToAttachFocus === '') {
      this.formRef.current.focus();
    }

    // console.log(this.props.inputArrayLineState?.length);
    if (this.props.inputArrayLineState !== undefined && this.props.inputArrayLineState[0] !== undefined) {
      // const sdf = this.props.inputArrayLineState[0];
      this.handleFocusAssingmentNext(this.props.inputArrayLineState[0]);
    }
  }

  /*
   *
   */
  handleFocusAssingmentNext($id = '') {
    const target = this.formRef.current;
    //
    if (target) {
      for (let i = 0; i < target?.length; i++) {
        const objtoFocus: HTMLFormElement = target.elements[i];
        if (objtoFocus.nodeName.toString() === 'INPUT' && objtoFocus.getAttribute('id') && $id !== '') {
          if (objtoFocus.getAttribute('id') === $id) {
            objtoFocus.focus();
            objtoFocus.select();
          }
        }
      }
    }
  }

  /*
   *
   */
  handleSubmitOMV(e) {
    e.preventDefault();
    const target = e.target;
    let hasEl = false;
    let wsformData: string = this.props.omvFormClickedBtn ? 'BUTTON=' + this.props.omvFormClickedBtn : 'BUTTON=CLEAR';
    for (let i = 0; i < target.length; i++) {
      if (target.elements[i].type === 'text') {
        hasEl = true;
        wsformData += '|' + target.elements[i].getAttribute('name') + '=' + target.elements[i].value;
      }
    }
    // Closing statement
    wsformData += hasEl ? '|' : '';
    this.submitWebSocketForm(wsformData);
    this.props.setShowOpenModalWindow(false); // TODO: Should be triggered from a sagas methode
  }

  //

  omvPressedKey(event) {
    event.preventDefault();
  }

  /*
  * Div wrapper clicked sets the focus back to previous.
  */
  mouseClickHandler(event: MouseEvent) {
    event.preventDefault();

    //
    if (this.props.recordingState > 0 && (event.target as HTMLFormElement)?.name != undefined) {
      this.props.updateRecordingLog(<>{'MouseEvent --> ' + (event.target as HTMLFormElement)?.name}  {' Value: ' + (event.target as HTMLFormElement)?.value}<br /> </>);
    }

    const target = this.formRef.current;
    let isFocusActive = false;
    let rangeCount = 0;
    for (let i = 0; i < target.length; i++) {
      const objtoFocus: HTMLFormElement = target.elements[i];
      // Get any text selected      
      const selection = window.getSelection();
      rangeCount = (selection && selection.rangeCount > 0) ? selection.toString().length : 0;

      if (objtoFocus.nodeName.toString() === 'INPUT' && (document.activeElement === objtoFocus)) {
        isFocusActive = true;
        break;
      }
    }
    // if (rangeCount === 0)
    if (!isFocusActive && !this.props.showOpenModalWindow && rangeCount === 0) {
      if (this.props.currentFieldwithFocus)
        this.handleFocusAssingment(this.props.currentFieldwithFocus.getAttribute('id'));
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        {this.props.showOpenModalWindow ? (
          <OpenMWVitec omvpressedkey={(event) => this.omvPressedKey(event)} onsubmit={(e) => this.handleSubmitOMV(e)} />
        ) : null}


        <div>
          <div id='vseDivWrap' className={classes.vseWrapper} onMouseUp={(e) => this.mouseClickHandler(e)}>
            <div className={classes.vseElA}>
              <div id="section-to-print">
                {this.zForm ? (
                  <>
                    {' '}
                    <form
                      name={(this.zForm as any).name}
                      id={(this.zForm as any).name}
                      ref={this.formRef}
                      autoComplete="off"
                      method={'post'}
                      onSubmit={(e) => this.handleSubmit(e)}>
                      {this.handleHTMLResponseUpdate()}{' '}
                    </form>
                  </>
                ) : (
                  []
                )}
              </div>
            </div>

            <div className={classes.vseElB}>
              <div className={classes.vseKeypad}>
                <div className={classes.FunctionKeyBlock}>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f1" className={classes.bF}>F1</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f2" className={classes.bF}>F2</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f3" className={classes.bF}>F3</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f4" className={classes.bF}>F4</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f5" className={classes.bF}>F5</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f6" className={classes.bF}>F6</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f7" className={classes.bF}>F7</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f8" className={classes.bF}>F8</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f9" className={classes.bF}>F9</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f10" className={classes.bF}>F10</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f11" className={classes.bF}>F11</button>
                  <button onClick={(e) => this.handlePressedKey(e)} value="f12" className={classes.bF}>F12</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
interface PropsPassedFromParents {
  transacId?: any;
}

//
interface StateProps {
  classes?: any;
  currentFieldwithFocus?: any;
  defaultFieldToAttachFocus?: string;
  fieldToAttachFocus?: string;
  htmlContent?: Document;
  inputArrayState?: any[];
  inputArrayLineState?: any[];
  isFieldSelectEnabled?: boolean;
  isTransGetInProgress?: boolean;
  isTransPostInProgress?: boolean;
  istransVSEHelpFetchInProgress?: boolean;
  keyBindingFieldInputStateObj?: any;
  menuItemTarget?: MenuItemTarget;
  omvFormClickedBtn?: any;
  showHelpContent?: boolean;
  showOpenModalWindow?: boolean;
  termId?: string;
  progId?: string;
  menuItems?: MenuEl[];
  recordingState: number;
  urlCallBack: string;
  keyValuePairs4VSE: any[];
  page: PageImpl<any>;
  currentRowFocused: number;
}

//
const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentFieldwithFocus: state.trans.currentFieldwithFocus,
    defaultFieldToAttachFocus: state.trans.defaultFieldToAttachFocus,
    fieldToAttachFocus: state.trans.fieldToAttachFocus,
    htmlContent: state.trans.content,
    inputArrayLineState: state.trans.inputArrayLineState,
    inputArrayState: state.trans.inputArrayState,
    isFieldSelectEnabled: state.trans.isFieldSelectEnabled,
    isTransGetInProgress: state.trans.isTransGetInProgress,
    isTransPostInProgress: state.trans.isTransPostInProgress,
    istransVSEHelpFetchInProgress: state.trans.istransVSEHelpFetchInProgress,
    keyBindingFieldInputStateObj: state.trans.keyBindingFieldInputStateObj,
    menuItemTarget: state.menuItem.menuItemTarget,
    omvFormClickedBtn: state.trans.omvFormClickedBtn,
    showHelpContent: state.trans.showHelpContent,
    showOpenModalWindow: state.trans.showOpenModalWindow,
    termId: state.trans.termId,
    progId: state.trans.termId,
    menuItems: state.menuItem.menuItems,
    recordingState: state.utils.recordingState,
    urlCallBack: state.ui.urlCallBack,
    keyValuePairs4VSE: state.ui.keyValuePairs4VSE,
    page: state.NV03.page,
    currentRowFocused: state.NV03.currentRowFocused,

  };
};
//
interface DispatchProps {
  menuItemSetSelected: (val: any) => void;
  menuItemSetTarget: (val: any) => void;
  postFormData: (formData: HeirloomFormData) => void;
  setCurrentField: (val: string) => void;
  setCurrentFieldwithFocus: (val: any) => void;
  setHelpParams: (val: HjelpFormData) => void;
  setSearchInputFocused: (val: boolean) => void;
  setSelectionEnabled: (val: boolean) => void;
  setShowHelpContent: (val: boolean) => void;
  setShowOpenModalWindow: (val: boolean) => void;
  transFormDataToWS: (value: any) => void;
  transHelpGet: (val: HjelpFormData) => void;
  transHelpGetSuccess: (val: any) => void;
  updateInputState: (name: string, value: string) => void;
  updateKey: (key: string) => void;
  userLogoutFromTerminal: () => void;
  updateRecordingLog: (val: any) => void;
  transGetSuccess: (val: any, tid: string) => void;
  resetKeyValuePairs4VSE: () => void;
  setCurrentRowFocused: (val: number) => void;
  setKeyValuePairs4VSE: (val: any) => void;
  transSetProgrameId: (val: any) => void;
}
//
const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    updateInputState: (name: string, value: string) => dispatch(actions.updateInputState(name, value)),
    menuItemSetSelected: (val: any) => dispatch(actions.menuItemSetSelected(val)),
    menuItemSetTarget: (val: any) => dispatch(actions.menuItemSetTarget(val)),
    postFormData: (formData: HeirloomFormData) => dispatch(actions.transFormDataPost(formData)),
    setCurrentField: (val: string) => dispatch(actions.setFieldToAttachFocus(val)),
    setCurrentFieldwithFocus: (val: string) => dispatch(actions.setCurrentFieldwithFocus(val)),
    setHelpParams: (val: HjelpFormData) => dispatch(actions.setHelpParams(val)),
    setSearchInputFocused: (val: boolean) => dispatch(actions.setSearchInputFocused(val)),
    setSelectionEnabled: (val: boolean) => dispatch(actions.setFieldSelectEnabled(val)),
    setShowHelpContent: (val: boolean) => dispatch(actions.setShowHelpContent(val)),
    setShowOpenModalWindow: (val: boolean) => dispatch(actions.setShowOpenModalWindow(val)),
    transFormDataToWS: (value: any) => dispatch(actions.transFormDataToWS(value)), // : (value: any) => void;
    transHelpGet: (val: HjelpFormData) => dispatch(actions.transHelpGet(val)),
    transHelpGetSuccess: (val: any) => dispatch(actions.transHelpGetSuccess(val)),
    updateKey: (key: string) => dispatch(actions.updatePressedKey(key)),
    userLogoutFromTerminal: () => dispatch(actions.userLogoutFromTerminal()),
    updateRecordingLog: (val: any) => dispatch(actions.updateRecordingLog(val)),
    transGetSuccess: (val: any) => dispatch(actions.transGetSuccess(val, '')),
    resetKeyValuePairs4VSE: () => dispatch(actions.setKeyValuePairs4VSE([])),
    setCurrentRowFocused: (val: number) => dispatch(actionsNV03.setCurrentRowFocused(val)),
    setKeyValuePairs4VSE: (val: number) => dispatch(actions.setKeyValuePairs4VSE(val)),
    transSetProgrameId: (val: number) => dispatch(actions.transSetProgrameId(val)),
  }
}
//
type Props = PropsPassedFromParents & StateProps & DispatchProps;
//
const VSEContentStyled = withStyles(
  VSEContent,
  (theme) => ({
    main: {
      width: `calc(100vw - ${350}px)`,
    },
    vseWrapper: {
      width: '100vw',
      //display: 'flex', //Doesn't resize well with flex
      marginLeft: '20px', 
      zIndex: 2500, marginTop: '55px',
      [C.r(theme, C.XXS, C.XXX)]: { marginTop: '62px' },
      [C.r(theme, C.XS, C.XSX)]: { marginTop: '62px', marginLeft: '20px' },
      [C.r(theme, C.SM, C.SMX)]: { marginTop: '62px' },
      [C.r(theme, C.MD, C.MDX)]: { marginTop: '65px' },
      [C.r(theme, C.LG, C.LGX)]: { marginTop: '65px' },
      [C.r(theme, C.XL, C.XLX)]: { marginTop: '65px' },
      [C.r(theme, C.XXL, C.YLX)]: { marginTop: '65px' },
      [C.IPAD_10]: { marginTop: '90px', marginLeft: '61px', width: `calc(100vw - ${70}px)`, },
      [C.SAMSUNG]: { marginTop: '50px', marginLeft: '18px' },
      outline: 'none',
    },
    vseElA: {
      position: 'relative',
      border: 'none',
      borderRadius: 5,
      backgroundColor: '#000',
      fontFamily: 'PT Mono, Courier New',
      fontSize: '1em',
      color: 'green',
      width: `calc(97vh * ${1.13})`,
      height: `calc(100vh  - ${90}px)`,
      [C.r(theme, C.XXS, C.XXX)]: { height: `calc(100vh  - ${78}px)` },
      [C.r(theme, C.XS, C.XSX)]: { height: `calc(100vh  - ${78}px)` },
      [C.r(theme, C.SM, C.SMX)]: { height: `calc(100vh  - ${78}px)` },
      [C.r(theme, C.MD, C.MDX)]: { height: `calc(100vh  - ${82}px)` },
      [C.r(theme, C.LG, C.LGX)]: { height: `calc(100vh  - ${82}px)` },
      [C.r(theme, C.XL, C.XLX)]: { height: `calc(100vh  - ${82}px)` },
      [C.r(theme, C.XXL, C.YLX)]: { height: `calc(100vh  - ${82}px)` },
      [C.IPAD_10]: { width: `calc(97vh * ${1.13})`, height: `calc(100vh  - ${140}px)` },
      [C.SAMSUNG]: { height: `calc(100vh  - ${160}px)` },
      boxShadow: 'rgba(100, 100, 100, .5) 5px 5px 7px',
    },
    vseElB: {
      marginLeft: 28, visibility: 'hidden',
      [C.IPAD_10]: { visibility: 'visible' },
      [C.SAMSUNG]: { width: '60px', marginLeft: 15, visibility: 'visible' },
    },
    //
    FunctionKeyBlock: { display: 'table', width: '15vh', height: `calc(100vh  - ${90}px)`, },
    //
    bF: {
      marginBottom: `calc(3.2vh  - ${6}px)`, background: '#222328', color: 'white',
      width: '100%', height: '5vh', fontSize: '1.3em', textTransform: 'uppercase', cursor: 'pointer',
      '&:hover': { background: '#84565d', color: 'white', transform: 'scale(1.05)', transitionDuration: '0.1s', },
      [C.IPAD_10]: { marginBottom: `calc(2.2vh  - ${6}px)` },
      [C.SAMSUNG]: { marginBottom: `calc(2.2vh  - ${6}px)`, width: '60px' },
    },
  })
);
//
export default connect<StateProps, DispatchProps, PropsPassedFromParents, AppState>(mapStateToProps, mapDispatchToProps)(VSEContentStyled);
