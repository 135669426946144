import { createRouterReducerMapObject, ReduxRouterState } from '@lagunovsky/redux-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import accountingReducer, * as fromAccounting from './reducers/accounting.reducers';
import authReducer, * as fromAuth from './reducers/auth.reducers';
import errorReducer, * as fromError from './reducers/error.reducers';
import menuItemReducer, * as fromMenuItem from './reducers/menuIterm.reducers';
import profileReducer, * as fromProfile from './reducers/profile.reducers';
import searchReducer, * as fromSearch from './reducers/search.reducers';
import supportReducer, * as fromSupport from './reducers/support.reducers';
import tallenesTaleReducer, * as fromTallenesTale from './reducers/tallenesTale.reducers';
import transReducer, * as fromTrans from './reducers/trans.reducers';
import uiReducer, * as fromUI from './reducers/ui.reducers';
import userAdminReducer, * as fromUserAdmin from './reducers/userAdmin.reducers';
import utilsReducer, * as fromUtils from './reducers/utils.reducers';
import NV03Reducer, * as fromNV03 from './reducers/NV03.reducers';
import NO01Reducer, * as fromNO01 from './reducers/NO01.reducers';
import NV02Reducer,* as fromNV02 from './reducers/NV02.reducers'
import NV01SALGReducer, * as fromNV01SALG from './reducers/NV01.reducers';
import { RESET_STORE } from './actions/actionTypes';


const appReducer = (history: History) =>
  combineReducers({
    router: createRouterReducerMapObject(history).router,
    auth: authReducer,
    profile: profileReducer,
    utils: utilsReducer,
    support: supportReducer,
    accounting: accountingReducer,
    menuItem: menuItemReducer,
    search: searchReducer,
    ui: uiReducer,
    trans: transReducer,
    error: errorReducer,
    userAdmin: userAdminReducer,
    tallenesTale: tallenesTaleReducer,
    NV03: NV03Reducer,
    NO01: NO01Reducer,
    NV02:NV02Reducer,
    NV01SALG: NV01SALGReducer,
  });


  
const rootReducer = (history: History) => (state, action) => {
  if (action.type === RESET_STORE) {
    // const { menuItem } = state; // Preserve menuItem state
    // state = { menuItem }; // Reset the rest of the state
     state = undefined; // reset the state
  }
  return appReducer(history)(state, action);
};

export default rootReducer;

export type AppState = {
  router: ReduxRouterState;
  auth: fromAuth.StateAuthCred;
  profile: fromProfile.StateProfile;
  utils: fromUtils.StateUtils;
  accounting: fromAccounting.StateAccounting;
  menuItem: fromMenuItem.StateMenuItem;
  search: fromSearch.StateSearch;
  ui: fromUI.StateUI;
  trans: fromTrans.StateTrans;
  error: fromError.StateError;
  userAdmin: fromUserAdmin.StateUserAdmin;
  tallenesTale: fromTallenesTale.STALE;
  NV03: fromNV03.NV03;
  NO01: fromNO01.NO01;
  NV02: fromNV02.NV02;
  NV01SALG: fromNV01SALG.NV01SALG;
  support: fromSupport.StateSupport;
};