import { put } from 'redux-saga/effects';
import { fetchProductGroupsMetrics, fetchWarehouseMetrics } from '../../felles/APICalls_NV02';
import * as actions from '../../store/actions';
import * as actionsNV02 from '../actions/indexNV02';
import * as actionTypes from '../actions/actionTypesNV02';

export function* fetchLavaMetricsSaga(action) {
  try {
    yield put(actions.setIsloading(true));

    let response;

    if (action.type === actionTypes.PRODUCT_METRICS_GET) {
      response = yield fetchProductGroupsMetrics(action.payload.companyId, action.payload.warehouse);
    } else if (action.type === actionTypes.WAREHOUSE_METRICS_GET) {
      response = yield fetchWarehouseMetrics(action.payload.companyId, action.payload.warehouse);
    }

    if (response) {
      if (action.type === actionTypes.PRODUCT_METRICS_GET) {
        yield put(actionsNV02.fetchProductMetricsSuccess(response));
      } else if (action.type === actionTypes.WAREHOUSE_METRICS_GET) {
        yield put(actionsNV02.fetchWarehouseMetricsSuccess(response));
      }
    } else {
      if (action.type === actionTypes.WAREHOUSE_METRICS_GET) {
        yield put(actionsNV02.fetchWarehouseMetricsFailure("No data received"));
      } else {
        yield put(actionsNV02.fetchProductMetricsFailure("No data received"));
      }
    }
  } catch (error) {
    console.error("Saga error: ", error);

    if (action.type === actionTypes.WAREHOUSE_METRICS_GET) {
      yield put(actionsNV02.fetchWarehouseMetricsFailure("Error fetching warehouse metrics"));
    } else {
      yield put(actionsNV02.fetchProductMetricsFailure("Error fetching product metrics"));
    }
  } finally {
    yield put(actions.setIsloading(false));
  }
}
