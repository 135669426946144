import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { YearlySalesData, } from "../../../models/NV01SALG";

interface YearlySalesTableProps {
  data: YearlySalesData[];
}

const YearlySalesTable: React.FC<YearlySalesTableProps> = ({ data }) => {
 
  const months: Record<string, string> = {
    jan: "Januar",
    feb: "Februar",
    march: "Mars",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    aug: "August",
    sept: "September",
    okt: "Oktober",
    nov: "November",
    des: "Desember",
  };

  const formatAmount = (amount: number): string => {
    const formattedAmount = amount.toLocaleString("no-NO", {});
    return formattedAmount.replace(/\s/g, ".");
  };

  // Calculate totals for each year's data
  const totals = data.map((yearData) => {
    const totalAntall = Object.values(yearData.months).reduce(
      (acc, month) => acc + (month?.count ?? 0),
      0
    );
    const totalBelop = Object.values(yearData.months).reduce(
      (acc, month) => acc + (month?.total_amount ?? 0),
      0
    );
    return { totalAntall, totalBelop };
  });

  return (
    <Paper>
      {/* Combine header and body in one scrollable TableContainer */}
      <TableContainer sx={{ maxHeight: "none" }}>
        <Table stickyHeader>
          {/* Header */}
          <TableHead>
            {/* Year row */}
            <TableRow sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}>
              <TableCell />
              {data.map((d, index) => (
                <TableCell
                  key={index}
                  colSpan={2}
                  align="center"
                  sx={{ color: "black", fontWeight: "bold" }}
                >
                  {d.year}
                </TableCell>
              ))}
            </TableRow>
            {/* Month/Belop row */}
            <TableRow sx={{ position: "sticky", top: 36, backgroundColor: "white", zIndex: 1 }}>
              <TableCell sx={{ color: "black", fontWeight: "bold" }}>Måned</TableCell>
              {data.map((_, index) => (
                <React.Fragment key={index}>
                  <TableCell sx={{ color: "black", fontWeight: "bold" }} align="right">
                    Antall
                  </TableCell>
                  <TableCell sx={{ color: "black", fontWeight: "bold" }} align="right">
                    Beløp
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {Object.keys(months).map((m, index) => (
              <TableRow key={index}>
                <TableCell>{months[m]}</TableCell>
                {data.map((d, index) => (
                  <React.Fragment key={index}>
                    <TableCell align="right">{d.months[m]?.count ?? ""}</TableCell>
                    <TableCell align="right">
                      {d.months[m]?.total_amount !== undefined
                        ? formatAmount(d.months[m].total_amount)
                        : ""}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
            {/* Separator row */}
            <TableRow sx={{ borderTop: "2px solid black" }}></TableRow>
            {/* Total row */}
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
              {totals.map((total, index) => (
                <React.Fragment key={index}>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {total.totalAntall}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {formatAmount(total.totalBelop)}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default YearlySalesTable;
