import axios, { AxiosRequestConfig } from 'axios';
import {ProductGroupsResponse,WarehouseMetrics} from "../models/NV02Lava";
import CSharpUtils from './CSharpUtils';
import Utils from './Utils';

export const fetchProductGroupsMetrics = (companyId: number,warehouse:number) => {
     const config: AxiosRequestConfig = {
    method: "get",
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/product/groups`,
     params: { companyId,
      warehouse,
     
      },
    headers: {
        accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`, 
    },
    responseType: "json",
    responseEncoding: "utf8",
    timeout: 10000,
  };

  return axios.request<ProductGroupsResponse>(config)
    .then(response => {
           return response.data;
    })
    .catch(error => {
      console.error("API Error: ", error); 
      throw error;
    });
};
export const fetchWarehouseMetrics = (companyId: number,warehouse:number) => {
  const config: AxiosRequestConfig = {
   method: "get",
   url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/product/warehouse/metrics`,
    params: { companyId,
     warehouse,
    
     },
   headers: {
       accept: 'application/json',
     Authorization: `Bearer ${CSharpUtils.Get_Token}`, 
   },
   responseType: "json",
   responseEncoding: "utf8",
   timeout: 10000,
 };

 return axios.request<WarehouseMetrics>(config)
   .then(response => {
        return response.data;
   })
   .catch(error => {
     console.error("API Error: ", error); 
     throw error;
   });
};
