export class LogMsg {
  _message: string;
  _payload: any;

  constructor(message: string, payload: any) {
    this._message = message;
    this._payload = payload;
  }

  public get message(): string {
    return this._message;
  }

  public set message(value: string) {
    this._message = value;
  }

  public get payload(): any {
    return this._payload;
  }

  public set payload(value: any) {
    this._payload = value;
  }
}
