import * as actionTypesNV02 from "./actionTypesNV02";
import { ProductGroupsResponse, WarehouseMetricsResponse } from '../../models/NV02Lava';

// WAREHOUSE METRICS ACTIONS
export const fetchWarehouseMetricsRequest = (companyId: number, warehouse: number) => ({
  type: actionTypesNV02.WAREHOUSE_METRICS_GET,
  payload: { companyId, warehouse },
});

export const fetchWarehouseMetricsSuccess = (data: WarehouseMetricsResponse) => ({
  type: actionTypesNV02.WAREHOUSE_METRICS_GET_SUCCESS,
  payload: data,
});

export const fetchWarehouseMetricsFailure = (error: string) => ({
  type: actionTypesNV02.WAREHOUSE_METRICS_GET_FAILURE,
  payload: error,
});

export const fetchWarehouseMetricsReset = () => ({
  type: actionTypesNV02.WAREHOUSE_METRICS_RESET,
});

// PRODUCT METRICS ACTIONS
export const fetchProductMetricsRequest = (companyId: number, warehouse: number) => ({
  type: actionTypesNV02.PRODUCT_METRICS_GET,
  payload: { companyId, warehouse },
});

export const fetchProductMetricsSuccess = (data: ProductGroupsResponse) => ({
  type: actionTypesNV02.PRODUCT_METRICS_GET_SUCCESS,
  payload: data,
});

export const fetchProductMetricsFailure = (error: string) => ({
  type: actionTypesNV02.PRODUCT_METRICS_GET_FAILURE,
  payload: error,
});

export const fetchProductMetricsReset = () => ({
  type: actionTypesNV02.PRODUCT_METRICS_RESET,
});
