export default class TalenesTale {
    private _id: number;
    private _firmId: string;
    private _varegruppe: string;
    private _navn: string;
    private _hovedgruppe: string;
    private _gruppe: string;
    private _summeringsgruppe: string;

    constructor(id: number, firmId: string, varegruppe: string, navn = '', hovedgruppe = '', gruppe = '', summeringsgruppe = '') {
        this._id = id
        this._firmId = firmId;
        this._varegruppe = varegruppe;
        this._navn = navn;
        this._hovedgruppe = hovedgruppe;
        this._gruppe = gruppe;
        this._summeringsgruppe = summeringsgruppe;
    }

    get id(): number {
        return this._id;
    }

    get firmId(): string {
        return this._firmId.toUpperCase();
    }

    get varegruppe(): string {
        return this._varegruppe;
    }

    get navn(): string {
        return this._navn;
    }

    get hovedgruppe(): string {
        return this._hovedgruppe;
    }

    get gruppe(): string {
        return this._gruppe;
    }

    get summeringsgruppe(): string {
        return this._summeringsgruppe;
    }

}


export class TaleSearchParams {
    private _parentId: number;
    private _butikkId: number;
    private _butikk: string;
    private _datoA: string;
    private _datoB: string;
    private _datoC: string;
    private _datoD: string;
    private _visning: string;
    private _brutto: boolean;
    private _bruttofortjeneste: boolean;
    private _mediaType: string;
    private _displayDate: string;
    private _subButikkId: number;
    private _subButikkNavn: string;
    private _datoAFO: string;
    private _datoBFO: string;
    private _datoCFO: string;
    private _datoDFO: string;

    constructor(parentId: 0, butikkId: 0, butikk: '', datoA = '', datoB = '', datoC = '', datoD = ''
        , visning = '', brutto = false, bruttofortjeneste = false, mediaType = 'web', displayDate = '0000-00-00 0000-00-00.....'
        , subButikkId = 0, subButikkNavn = 'none', datoAFO = '', datoBFO = '', datoCFO = '', datoDFO = '') {
        this._parentId = parentId;
        this._butikkId = butikkId;
        this._butikk = butikk;
        this._datoA = datoA;
        this._datoB = datoB;
        this._datoC = datoC;
        this._datoD = datoD;
        this._visning = visning;
        this._brutto = brutto;
        this._bruttofortjeneste = bruttofortjeneste;
        this._mediaType = mediaType;
        this._displayDate = displayDate;
        this._subButikkId = subButikkId;
        this._subButikkNavn = subButikkNavn;
        this._datoAFO = datoAFO;
        this._datoBFO = datoBFO;
        this._datoCFO = datoCFO;
        this._datoDFO = datoDFO;
    }

    get parentId(): number { return this._parentId; }
    //
    get butikkId(): number { return this._butikkId; }

    get butikk(): string { return this._butikk; }

    get datoA(): string { return this._datoA; }

    set datoA(val: string) { this._datoA = val; }

    get datoB(): string { return this._datoB; }

    set datoB(val: string) { this._datoB = val; }

    get datoC(): string { return this._datoC; }

    set datoC(val: string) { this._datoC = val; }

    get datoD(): string { return this._datoD; }

    set datoD(val: string) { this._datoD = val; }

    get visning(): string { return this._visning; }

    get brutto(): boolean { return this._brutto; }

    get bruttofortjeneste(): boolean { return this._bruttofortjeneste; }

    get mediaType(): string { return this._mediaType; }

    get displayDate(): string { return this._displayDate; }

    get subButikkId(): number { return this._subButikkId; }

    get subButikkNavn(): string { return this._subButikkNavn; }

    get datoAFO(): string { return this._datoAFO; }

    set datoAFO(val: string) { this._datoAFO = val; }

    get datoBFO(): string { return this._datoBFO; }

    set datoBFO(val: string) { this._datoBFO = val; }

    get datoCFO(): string { return this._datoCFO; }

    set datoCFO(val: string) { this._datoCFO = val; }

    get datoDFO(): string { return this._datoDFO; }

    set datoDFO(val: string) { this._datoDFO = val; }
}

export class TaleFavorittListe {
    private _id: number;
    private _firmId: string;
    private _butikkId: number;
    private _butikk: string;
    private _startDate: Date;
    private _endDate: Date;
    private _visning: string;
    private _brutto: boolean;
    private _bruttofortjeneste: boolean;
    private _betegnelse: string;
    private _merknader: string;
    private _subButikkId: number;
    private _subButikk: string;

    constructor(id: 0, firmId: '', butikkId: 0, butikk: '', startDate: Date, endDate: Date, visning = ''
        , brutto = false, bruttofortjeneste = false,  betegnelse = '', merknader = '', subButikkId = 0, subButikk = '') {
        this._id = id
        this._firmId = firmId;
        this._butikkId = butikkId;
        this._butikk = butikk;
        this._startDate = startDate;
        this._endDate = endDate;
        this._visning = visning;
        this._brutto = brutto;
        this._bruttofortjeneste = bruttofortjeneste;
        this._betegnelse = betegnelse;
        this._merknader = merknader;
        this._subButikkId = subButikkId;
        this._subButikk = subButikk;
    }

    get id(): number {
        return this._id;
    }

    get firmId(): string {
        return this._firmId.toUpperCase();
    }

    get butikkId(): number {
        return this._butikkId;
    }

    get butikk(): string {
        return this._butikk;
    }

    get startDate(): Date {
        return this._startDate;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get visning(): string {
        return this._visning;
    }

    get brutto(): boolean {
        return this._brutto;
    }

    get bruttofortjeneste(): boolean {
        return this._bruttofortjeneste;
    }

    get betegnelse(): string {
        return this._betegnelse;
    }

    get merknader(): string {
        return this._merknader;
    }

    get subButikkId(): number {
        return this._subButikkId;
    }

    get subButikk(): string {
        return this._subButikk;
    }
}